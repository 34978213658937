import { Injectable } from "@angular/core";
// import { Workbook } from "exceljs";
import * as fs from "file-saver";
import { DatePipe } from "@angular/common";


@Injectable({
  providedIn: "root",
})
export class ExportExcelService {
  // Redmeption


  constructor(private datePipe: DatePipe) { }

  pipe = new DatePipe("en-US");

}



